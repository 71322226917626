import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

class DarkModeToggle extends React.Component {
  render() {
    const { darkMode, toggleDarkMode } = this.props;
    return (
      <FontAwesomeIcon
        icon={darkMode ? faSun : faMoon}
        onClick={toggleDarkMode}
        style={{ cursor: 'pointer', fontSize: '1.5rem',margin:'6px',color: darkMode ? '#f39c12' : '#2c3e50' }}
        title={darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
      />
    );
  }
}

export default DarkModeToggle;
