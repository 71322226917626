import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import classNames from "classnames";
import DarkModeToggle from "../plugins/DarkModeToggle";
import SearchComponent from '../plugins/search/SearchComponent';
import { ReactComponent as Logo } from "../assets/logo.svg";
import "../styles/Search.css";
import "../styles/NavBar.css";

const pages = [
  { title: "Home", path: "/", content: "<Home />" },
  { title: "Charts", path: "/charts", content: "<Charts />" },
  { title: "Finance Charts", path: "/FnCharts", content: "<FnCharts />" },
  { title: "Tech", path: "/tech", content: "<Tech />" },
  { title: "Library", path: "/library", content: "<Library />" },
  { title: "Music", path: "/musicplayer", content: "<MusicPlayer />" },
  { title: "About", path: "/about", content: "<About />" }
];

function NavBar({ toggleSidebar, darkMode, toggleDarkMode }) {
  const [hovered, setHovered] = useState(null);
  const [hoverBoxStyle, setHoverBoxStyle] = useState({});
  const navRef = useRef(null);

  const navItems = [
    { title: "Home", path: "/" },
    { title: "Charts", path: "/charts", subItems: [{ name: "Finance", path: "/FnCharts" }] },
    { title: "Tech", path: "/tech" },
    { title: "Library", path: "/library" },
    { title: "Music", path: "/musicplayer" },
    { title: "About", path: "/about" }
  ];

  useEffect(() => {
    if (hovered) {
      const link = document.getElementById(hovered);
      const { offsetLeft, offsetWidth } = link;
      setHoverBoxStyle({
        width: offsetWidth,
        left: offsetLeft,
      });
    }
  }, [hovered]);

  return (
    <Navbar className={`navbar shadow shadow-md box-shadow p-2 rounded ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'}`} ref={navRef}>
      <Button variant="outline-primary" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <a href="/"> <Logo style={{ height: '40px', width: '40px' }} className="" /></a>
      <Nav className={classNames("ml-auto", { 'dark-mode': darkMode })}>
        {navItems.map((item, index) => (
          <Nav.Link className="font-weight-bold"
            key={index}
            id={item.title.toLowerCase()}
            href={item.path}
            onMouseEnter={() => setHovered(item.title.toLowerCase())}
            onMouseLeave={() => setHovered(null)}
          >
            {item.title}
          </Nav.Link>
        ))}
      </Nav>
      <div className="ml-auto d-flex align-items-center">
        <SearchComponent pages={pages} />
        <DarkModeToggle toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      </div>
      {hovered && (
        <div
          className="hover-box"
          style={{ ...hoverBoxStyle, position: 'absolute', bottom: 0, height: '3px', backgroundColor: '#007bff', transition: 'all 0.3s ease' }}
        ></div>
      )}
    </Navbar>
  );
}

export default NavBar;