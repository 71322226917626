import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/sidebar/SideBar';
import NavBar from './components/content/Navbar';
import Footer from './components/plugins/Footer';
import SearchResults from './components/plugins/search/SearchResults';
import xmlParser from 'xml-js';
import './components/styles/styles.css';
import './index.css';
import { initGA, logEvent } from './analytics';
import RouteTracker from './RouteTracker';

// Lazy load components for client-side rendering
const Home = lazy(() => import('./components/pages/Home/Home'));
const BlogView = lazy(() => import('./components/pages/BlogView'));
const Tech = lazy(() => import('./components/pages/Tech'));
const Charts = lazy(() => import('./components/pages/charts/Charts'));
const FnCharts = lazy(() => import('./components/pages/charts/FnCharts'));
const MusicPlayer = lazy(() => import('./components/pages/MusicPlayer'));
const About = lazy(() => import('./components/pages/About'));
const FullArticle = lazy(() => import('./components/pages/FullArticle'));
const Library = lazy(() => import('./components/pages/Library'));

const stripHtmlTags = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html || '';
  return tempDiv.textContent || tempDiv.innerText || '';
};

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');
  const [pages, setPages] = useState([]);
  const [xmlData, setXmlData] = useState([]);
  const [jukeboxData, setJukeboxData] = useState([]);

  // Handle screen width changes for sidebar
  useEffect(() => {
    initGA();
    const updateWidth = () => {
      const width = window.innerWidth;
      const widthLimit = 992;
      setIsMobile(width <= widthLimit);
      setIsOpen(width > widthLimit || !isMobile);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [isMobile]);

  // Fetch blog data
  useEffect(() => {
    fetch('/blog.xml')
      .then((response) => response.text())
      .then((xml) => {
        const json = JSON.parse(xmlParser.xml2json(xml, { compact: true, spaces: 4 }));
        const data = Array.isArray(json.blog.posts.post) ? json.blog.posts.post : [json.blog.posts.post];
        setXmlData(data);
      })
      .catch((error) => console.error('Error fetching XML:', error));
  }, []);

  // Define static and dynamic pages
  useEffect(() => {
    const staticPages = [
      { title: 'Home', path: '/', component: Home, content: 'Home Page' },
      { title: 'Charts', path: '/charts', component: Charts, content: 'Charts Page' },
      { title: 'Finance Charts', path: '/fn-charts', component: FnCharts, content: 'Finance Charts Page' },
      { title: 'Blog', path: '/blog', component: BlogView, content: 'Blog Page' },
      { title: 'Library', path: '/library', component: Library, content: 'Library' },
      { title: 'Tech', path: '/tech', component: Tech, content: 'Tech Page' },
      { title: 'Music', path: '/musicplayer', component: MusicPlayer, content: 'Music Player Page' },
      { title: 'About', path: '/about', component: About, content: 'About Page' }
    ];

    const blogPages = xmlData.map((item, index) => ({
      title: item.title ? item.title._text : `Post ${index + 1}`,
      path: item.title ? `/post/${item.title._text.toLowerCase().replace(/ /g, '-')}` : `/post/${index + 1}`,
      component: FullArticle,
      content: item.content ? stripHtmlTags(item.content._cdata || '') : ''
    }));

    const jukeboxPages = jukeboxData.map((item, index) => ({
      title: item.title || `Song ${index + 1}`,
      path: `/musicplayer#${index}`,
      component: MusicPlayer,
      content: `${item.title || ''} ${item.artist || ''}`
    }));

    const allPages = [...staticPages, ...blogPages, ...jukeboxPages];
    setPages(allPages);
  }, [xmlData, jukeboxData, darkMode]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    logEvent('Sidebar', 'Toggled');
  };

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      localStorage.setItem('darkMode', newDarkMode);
      return newDarkMode;
    });
    logEvent('Dark Mode', darkMode ? 'Disabled' : 'Enabled');
  };

  return (
    <Router>
      <div className={`App wrapper ${darkMode ? 'dark-mode' : ''}`}>
        <NavBar
          toggleSidebar={toggleSidebar}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          pages={pages}
        />
        <div className={`content-wrapper container-fluid ${darkMode ? 'text-light bg-dark' : ''}`}>
          <SideBar toggle={toggleSidebar} isOpen={isOpen} darkMode={darkMode} />
          <RouteTracker />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {pages.map(({ path, component: Component, content }) => (
                <Route
                  key={path}
                  path={path}
                  element={<Component darkMode={darkMode} content={content} />}
                />
              ))}
              <Route path="/post/:title" element={<FullArticle darkMode={darkMode} />} />
              <Route path="/search" element={<SearchResults pages={pages} />} />
              {/* Fallback route for unmatched paths */}
              <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;