import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from './analytics';

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = pageTitle;
    logPageView(pageTitle);
  }, [location]);

  return null;
};

const getPageTitle = (pathname) => {
  if (pathname.startsWith('/post/')) {
    return 'Full Article';
  }

  switch (pathname) {
    case '/':
    case '/home':
      return 'Home';
    case '/charts':
      return 'Charts';
    case '/FnCharts':
      return 'Finance Charts';
    case '/blog':
      return 'Blog';
    case '/tech':
      return 'Tech';
    case '/musicplayer':
      return 'Music Player'; 
    case '/about':
      return 'About';
    default:
      return 'App';
  }
};

export default RouteTracker;