// SubMenu.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"; // Import faArrowRight icon
import "../styles/SubMenu.css"; // Import the custom CSS file
import { Link } from "react-router-dom";

class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  handleItemClick = () => {
    this.setState({ collapsed: true }); // Close submenu when item is clicked
    this.props.handleLinkClick(); // Close sidebar if needed
  };

  render() {
    const { title, icon, items } = this.props;
    const { collapsed } = this.state;

    return (
      <Nav.Item>
        <Nav.Link onClick={this.toggleCollapse} className="d-flex justify-content-between align-items-center">
          <span>
            <FontAwesomeIcon icon={icon} className="mr-2" />
            {title}
          </span>
          <FontAwesomeIcon
            icon={collapsed ? faArrowRight : faArrowRight} // Use faArrowRight here
            className={classNames("icon", { "rotate-icon": !collapsed })}
          />
        </Nav.Link>
        <div className={classNames("submenu", { "collapse": collapsed })}>
          {items.map((item, index) => (
            <Nav.Link as={Link} to={item.path} key={index} onClick={this.handleItemClick}>
              {item.name}
            </Nav.Link>
          ))}
        </div>
      </Nav.Item>
    );
  }
}

export default SubMenu;
