// src/components/Footer.js

import React from 'react';


const Footer = ({ darkMode }) => {
  return (
    <footer className={`footer ${darkMode ? 'dark-mode-footer' : ''}`}>
      <div className="container text-center">

        <div className="row">
          <div className="col-12 text-center">
            <p>&copy; 2024 ChartPangaea V 1.04</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
