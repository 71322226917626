import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faQuestion,
  faImage,
  faCopy,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./SubMenu";
import "../styles/SideBar.css"
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";
import "../styles/styles.css";
import { Link } from "react-router-dom";

class SideBar extends React.Component {
  handleLinkClick = () => {
    if (window.innerWidth <= 559) {
      this.props.toggle();
    }
  };

  render() {
    const { isOpen, toggle, darkMode } = this.props;

    return (
      <div className={classNames("rounded-right", "sidebar", "box-shadow", { "is-open": isOpen, "dark-mode": darkMode })}>

        <Nav className="flex-column pt-2">
          <Nav.Item>
            <Nav.Link as={Link} to="/" className="d-flex align-items-center" onClick={this.handleLinkClick}>
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Home
            </Nav.Link>
          </Nav.Item>

          <SubMenu
            title="Charts"
            icon={faCopy}
            items={[
              { name: "Macroeconomy", path: "/Charts" },
              { name: "Finance", path: "/FnCharts" }

            ]}
            handleLinkClick={this.handleLinkClick}
          />

          <Nav.Item>
            <Nav.Link as={Link} to="/tech" className="d-flex align-items-center" onClick={this.handleLinkClick}>
              <FontAwesomeIcon icon={faQuestion} className="mr-2" />
              Tech
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/library" className="d-flex align-items-center" onClick={this.handleLinkClick}>
              <FontAwesomeIcon icon={faQuestion} className="mr-2" />
              Library
            </Nav.Link>
          </Nav.Item>



          <Nav.Item>
            <Nav.Link as={Link} to="/musicplayer" className="d-flex align-items-center" onClick={this.handleLinkClick}>
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              Music Player
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/about" className="d-flex align-items-center" onClick={this.handleLinkClick}>
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
              About
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    );
  }
}

export default SideBar;
