
// analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-2Z74BVZNJZ'); // Replace with your GA4 Measurement ID
};

export const logPageView = (title) => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
};

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action
    });
  }
};
