import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';

const SearchResultsPage = ({ pages }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || '';

  const results = pages.filter(page => {
    const title = page.title ? page.title.toLowerCase() : '';
    const content = page.content ? page.content.toLowerCase() : '';
    return title.includes(query.toLowerCase()) || content.includes(query.toLowerCase());
  });

  return (
    <Container className="search-results-page mt-4">
      <h2 className="mb-4">Search Results for "{query}"</h2>
      {results.length > 0 ? (
        <Row>
          {results.map((result, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Link to={result.path} className="card-link">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>{result.title}</Card.Title>
                    <Card.Text>
                      {result.content ? result.content.substring(0, 200) : ''}...
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      ) : (
        <Alert variant="warning">No results found</Alert>
      )}
    </Container>
  );
};

export default SearchResultsPage;