import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormControl, Button, InputGroup } from 'react-bootstrap';

const SearchComponent = ({ pages }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (query.length > 0) {
      const searchQuery = query.toLowerCase();
      const filteredResults = pages.filter(page => {
        const title = page.title ? page.title.toLowerCase() : '';
        const content = page.content ? page.content.toLowerCase() : '';
        return title.includes(searchQuery) || content.includes(searchQuery);
      });
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [query, pages]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleResultClick = (path) => {
    setResults([]);  // Clear results after navigating
    setQuery('');    // Clear search query
    navigate(path);  // Navigate to the selected result
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query) {
      navigate(`/search?query=${query}`);
    }
  };

  return (
    <div className="search-component">
      <Form className="d-flex" onSubmit={handleSubmit}>
        <InputGroup size="m">
          <FormControl
            type="text"
            value={query}
            onChange={handleSearch}
            placeholder="Search..."
          />
          <Button type="submit" variant="primary">Search</Button>
        </InputGroup>
      </Form>
      {results.length > 0 && (
        <div className="search-results">
          {results.map((result, index) => (
            <div key={index} className="search-result-item">
              <Link to={result.path} onClick={() => handleResultClick(result.path)}>
                {result.title}
              </Link>
              <p>{result.content.substring(0, 100)}...</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;